@font-face {
  font-family: 'figtreelight_italic';
  src: url('./assets/Fonts/figtree-italic-variablefont_wght-webfont.woff2') format('woff2'),
       url('./assets/Fonts/figtree-italic-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Figtree', sans-serif;
}
.container{
  background-color: #F4D04E;
  width: 100%;
  min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  overflow-y: auto;

}
.card{
  width: 384px;
  height: 522px;
  border: 1px solid #111111;
  border-radius: 20px;
  background-color: #FFFFFF;
  box-shadow: 16px 16px #111111;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
}
.image img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.description{
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 194px;

  .heading{
    width: 82px;
    height: 29px;
    background-color: #F4D04E;
    color: #111111;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
  }
  .published{
    color: #111111;
    font-size: 14px;
  }
  .title{
    color: #F4D04E;
    font-size: 24px;
  }
  .desc{
    color: #7F7F7F;
    font-size: 16px;
  }

}
.profile{
  display: flex;
  gap: 12px;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
}
@media screen and (max-width: 400px) {
  .card{
    width: 327px;
    height: 501px
  }
  .title{
    color: #111111 !important;
    font-size: 20px !important;
    font-weight: bolder;
  }
  .desc{
    font-size: 14px;
  }
}
